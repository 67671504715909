<template>
  <div class="number-circle" v-html="favoriteListId.length"></div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'favorite-icon',
  computed: {
    ...mapGetters('favoriteList', ['getFavoriteList']),
    ...mapState('favoriteList', ['favoriteListId']),
    ...mapActions('favoriteList', [
      'favoriteListIdRequest',
    ]),
  },
  mounted() {
    if ('function' === typeof this.favoriteListIdRequest) {
      this.favoriteListIdRequest()
    }
  },
}
</script>
<style lang="scss" scoped>
.number-circle {
  position: absolute;
  bottom: 17px;
  left: 6px;

  z-index: 999999;

  border-radius: 30px;
  background: #F2F2F2;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 17px;
  height: 17px;

  padding: 4px;

  font-family: Open Sans;
  font-size: 8px;
  font-weight: 600;
  line-height: 8px;
  letter-spacing: -0.30000001192092896px;
  color: black;

  @media (max-width: 1200px) {
    bottom: -6px;
    left: 9px;
  }
}
</style>
