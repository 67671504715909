<template>
  <div class="container">
    <h1 class="price__title">Ціни</h1>
    <div class="price__cards">
      <div class="price__card">
        <p class="price__card-title">Для Клієнтів</p>
        <p class="price__card-text">Оплата за кожен окремий кастинг. Вартість кастингу залежить від кількості анкет. Викуплені анкети залишаються відкритими назавжди.</p>
        <div class="price__card-list">
          <div class="price__card-list-item">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <mask id="mask0_99_1494" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
              <rect width="24" height="24" fill="#D9D9D9"/>
              </mask>
              <g mask="url(#mask0_99_1494)">
              <path d="M12 11.6921C11.0375 11.6921 10.2136 11.3495 9.52825 10.6641C8.84275 9.97864 8.5 9.15464 8.5 8.19214C8.5 7.22964 8.84275 6.40572 9.52825 5.72039C10.2136 5.03489 11.0375 4.69214 12 4.69214C12.9625 4.69214 13.7864 5.03489 14.4718 5.72039C15.1573 6.40572 15.5 7.22964 15.5 8.19214C15.5
                9.15464 15.1573 9.97864 14.4718 10.6641C13.7864 11.3495 12.9625 11.6921 12 11.6921ZM4.5 17.7884V17.0844C4.5 16.5947 4.633 16.1412 4.899 15.7239C5.165 15.3066 5.5205 14.9857 5.9655 14.7614C6.95383 14.2769 7.95092 13.9135 8.95675 13.6711C9.96258 13.4288 10.977 13.3076 12 13.3076C13.023 13.3076 14.0374 13.4288 15.0433 13.6711C16.0491 13.9135 17.0462
                14.2769 18.0345 14.7614C18.4795 14.9857 18.835 15.3066 19.101 15.7239C19.367 16.1412 19.5 16.5947 19.5 17.0844V17.7884C19.5 18.2101 19.3523 18.5687 19.0568 18.8644C18.7613 19.1599 18.4026 19.3076 17.9808 19.3076H6.01925C5.59742 19.3076 5.23875 19.1599 4.94325 18.8644C4.64775 18.5687 4.5 18.2101 4.5 17.7884ZM6 17.8076H18V17.0844C18 16.8819 17.9413 16.6944
                17.824 16.5219C17.7067 16.3496 17.5474 16.2089 17.3462 16.0999C16.4846 15.6756 15.6061 15.3541 14.7107 15.1354C13.8152 14.9169 12.9117 14.8076 12 14.8076C11.0883 14.8076 10.1848 14.9169 9.28925 15.1354C8.39392 15.3541 7.51542 15.6756 6.65375 16.0999C6.45258 16.2089 6.29333 16.3496 6.176 16.5219C6.05867 16.6944 6 16.8819 6 17.0844V17.8076ZM12 10.1921C12.55 10.1921 13.0208 9.99631 13.4125 9.60464C13.8042 9.21297 14 8.74214 14 8.19214C14 7.64214 13.8042 7.17131 13.4125 6.77964C13.0208 6.38797 12.55 6.19214 12 6.19214C11.45 6.19214 10.9792 6.38797 10.5875 6.77964C10.1958 7.17131 10 7.64214 10 8.19214C10 8.74214 10.1958 9.21297 10.5875 9.60464C10.9792 9.99631 11.45 10.1921 12 10.1921Z" fill="#7069A9"/>
              </g>
            </svg>
            <div class="price__card-list-text">До 3х анкет – 800 грн/контакт</div>
          </div>
          <div class="price__card-list-item">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <mask id="mask0_99_1497" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
              <rect width="24" height="24" fill="#D9D9D9"/>
              </mask>
              <g mask="url(#mask0_99_1497)">
                <path d="M18.125 10.7499H15.875C15.6625 10.7499 15.4844 10.678 15.3408 10.5341C15.1969 10.3903 15.125 10.2121 15.125 9.99964C15.125 9.78697 15.1969 9.60889 15.3408 9.46539C15.4844 9.32172 15.6625 9.24989 15.875 9.24989H18.125V6.99989C18.125 6.78739 18.1969 6.60931 18.3407 6.46564C18.4846 6.32181 18.6628 6.24989 18.8752
                  6.24989C19.0879 6.24989 19.266 6.32181 19.4095 6.46564C19.5532 6.60931 19.625 6.78739 19.625 6.99989V9.24989H21.875C22.0875 9.24989 22.2656 9.32181 22.4093 9.46564C22.5531 9.60947 22.625 9.78764 22.625 10.0001C22.625 10.2128 22.5531 10.3909 22.4093 10.5344C22.2656 10.6781 22.0875 10.7499 21.875 10.7499H19.625V12.9999C19.625 13.2124 19.5531
                  13.3905 19.4093 13.5341C19.2654 13.678 19.0872 13.7499 18.8748 13.7499C18.6621 13.7499 18.484 13.678 18.3405 13.5341C18.1968 13.3905 18.125 13.2124 18.125 12.9999V10.7499ZM9 11.6921C8.0375 11.6921 7.21358 11.3495 6.52825 10.6641C5.84275 9.97864 5.5 9.15464 5.5 8.19214C5.5 7.22964 5.84275 6.40572 6.52825 5.72039C7.21358 5.03489 8.0375 4.69214
                    9 4.69214C9.9625 4.69214 10.7864 5.03489 11.4718 5.72039C12.1573 6.40572 12.5 7.22964 12.5 8.19214C12.5 9.15464 12.1573 9.97864 11.4718 10.6641C10.7864 11.3495 9.9625 11.6921 9 11.6921ZM1.5 17.7884V17.0844C1.5 16.5947 1.633 16.1412 1.899 15.7239C2.165 15.3066 2.5205 14.9857 2.9655 14.7614C3.95383 14.2769 4.95092 13.9135 5.95675 13.6711C6.96258
                    13.4288 7.977 13.3076 9 13.3076C10.023 13.3076 11.0374 13.4288 12.0433 13.6711C13.0491 13.9135 14.0462 14.2769 15.0345 14.7614C15.4795 14.9857 15.835 15.3066 16.101 15.7239C16.367 16.1412 16.5 16.5947 16.5 17.0844V17.7884C16.5 18.2101 16.3523 18.5687 16.0568 18.8644C15.7613 19.1599 15.4026 19.3076 14.9808 19.3076H3.01925C2.59742 19.3076 2.23875 19.1599 1.94325 18.8644C1.64775 18.5687 1.5 18.2101 1.5 17.7884ZM3 17.8076H15V17.0844C15 16.8819 14.9413 16.6944 14.824 16.5219C14.7067 16.3496 14.5474 16.2089 14.3462 16.0999C13.4846 15.6756 12.6061
                    15.3541 11.7107 15.1354C10.8152 14.9169 9.91167 14.8076 9 14.8076C8.08833 14.8076 7.18475 14.9169 6.28925 15.1354C5.39392 15.3541 4.51542 15.6756 3.65375 16.0999C3.45258 16.2089 3.29333 16.3496 3.176 16.5219C3.05867 16.6944 3 16.8819 3 17.0844V17.8076ZM9 10.1921C9.55 10.1921 10.0208 9.99631 10.4125 9.60464C10.8042 9.21297 11 8.74214 11 8.19214C11 7.64214 10.8042 7.17131 10.4125 6.77964C10.0208 6.38797 9.55 6.19214 9 6.19214C8.45 6.19214 7.97917 6.38797 7.5875 6.77964C7.19583 7.17131 7 7.64214 7 8.19214C7 8.74214 7.19583 9.21297 7.5875 9.60464C7.97917 9.99631 8.45 10.1921 9 10.1921Z"
                fill="#7069A9"/>
              </g>
            </svg>

            <div class="price__card-list-text">4-15 анкет – 680грн/контакт</div>
            <div class="price__card-list-label">-15 %</div>
          </div>
          <div class="price__card-list-item">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <mask id="mask0_99_1504" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                <rect width="24" height="24" fill="#D9D9D9"/>
              </mask>
              <g mask="url(#mask0_99_1504)">
                <path d="M12.5957 11.6421C13.0212 11.1793 13.336 10.6479 13.54 10.0479C13.7438 9.44789 13.8457 8.82931 13.8457 8.19214C13.8457 7.55497 13.7438 6.93639 13.54 6.33639C13.336 5.73639 13.0212 5.20497 12.5957 4.74214C13.4739 4.84347 14.203 5.22264 14.7832 5.87964C15.3632 6.53681 15.6532 7.30764 15.6532 8.19214C15.6532 9.07681 15.3632 9.84764 14.7832 10.5046C14.203 11.1616 13.4739 11.5408 12.5957 11.6421ZM17.459 19.3076C17.5846 19.0781 17.6805 18.8339 17.7465 18.5749C17.8126
                  18.3159 17.8457 18.0537 17.8457 17.7884V16.9614C17.8457 16.4162 17.7348 15.8976 17.513 15.4054C17.2911 14.9132 16.9764 14.4909 16.5687 14.1384C17.3354 14.3936 18.0411 14.7378 18.686 15.1711C19.3308 15.6045 19.6532 16.2012 19.6532 16.9614V17.7884C19.6532 18.2101 19.5055 18.5687 19.21 18.8644C18.9145 19.1599 18.5559 19.3076 18.1342 19.3076H17.459ZM19.6532 10.7499H18.4032C18.1907 10.7499 18.0126 10.678 17.869 10.5341C17.7251 10.3903 17.6532 10.2121 17.6532 9.99964C17.6532 9.78697 17.7251 9.60889
                  17.869 9.46539C18.0126 9.32172 18.1907 9.24989 18.4032 9.24989H19.6532V7.99989C19.6532 7.78739 19.7251 7.60931 19.869 7.46564C20.0128 7.32181 20.191 7.24989 20.4037 7.24989C20.6162 7.24989 20.7943 7.32181 20.938 7.46564C21.0815 7.60931 21.1532 7.78739 21.1532 7.99989V9.24989H22.4032C22.6157 9.24989 22.7939 9.32181 22.9377 9.46564C23.0814 9.60947 23.1532 9.78764 23.1532 10.0001C23.1532 10.2128 23.0814 10.3909 22.9377 10.5344C22.7939 10.6781 22.6157 10.7499 22.4032 10.7499H21.1532V11.9999C21.1532 12.2124
                    21.0813 12.3905 20.9375 12.5341C20.7936 12.678 20.6155 12.7499 20.403 12.7499C20.1903 12.7499 20.0122 12.678 19.8687 12.5341C19.725 12.3905 19.6532 12.2124 19.6532 11.9999V10.7499ZM8.3457 11.6921C7.3832 11.6921 6.5592 11.3495 5.8737 10.6641C5.18837 9.97864 4.8457 9.15464 4.8457 8.19214C4.8457 7.22964 5.18837 6.40572 5.8737 5.72039C6.5592 5.03489 7.3832 4.69214 8.3457 4.69214C9.3082 4.69214 10.1321 5.03489 10.8175 5.72039C11.503 6.40572 11.8457 7.22964 11.8457 8.19214C11.8457 9.15464 11.503 9.97864 10.8175 10.6641C10.1321
                    11.3495 9.3082 11.6921 8.3457 11.6921ZM0.845703 17.7884V17.0844C0.845703 16.5947 0.978703 16.1412 1.2447 15.7239C1.5107 15.3066 1.86612 14.9857 2.31095 14.7614C3.29945 14.2769 4.29662 13.9135 5.30245 13.6711C6.30812 13.4288 7.32254 13.3076 8.3457 13.3076C9.3687 13.3076 10.3831 13.4288 11.389 13.6711C12.3946 13.9135 13.3917 14.2769 14.3802 14.7614C14.825 14.9857 15.1805 15.3066 15.4465 15.7239C15.7126 16.1412 15.8457 16.5947 15.8457 17.0844V17.7884C15.8457 18.2101 15.698 18.5687 15.4025 18.8644C15.107 19.1599 14.7483 19.3076 14.3265 19.3076H2.36495C1.94312
                      19.3076 1.58445 19.1599 1.28895 18.8644C0.993453 18.5687 0.845703 18.2101 0.845703 17.7884ZM8.3457 10.1921C8.8957 10.1921 9.36654 9.99631 9.7582 9.60464C10.1499 9.21297 10.3457 8.74214 10.3457 8.19214C10.3457 7.64214 10.1499 7.17131 9.7582 6.77964C9.36654 6.38797 8.8957 6.19214 8.3457 6.19214C7.7957 6.19214 7.32487 6.38797 6.9332 6.77964C6.54154 7.17131 6.3457 7.64214 6.3457 8.19214C6.3457 8.74214 6.54154 9.21297 6.9332 9.60464C7.32487 9.99631 7.7957 10.1921 8.3457 10.1921ZM2.3457 17.8076H14.3457V17.0844C14.3457 16.8819 14.287 16.6944 14.1697 16.5219C14.0524 16.3496 13.893 16.2089 13.6917
                      16.0999C12.8302 15.6756 11.9518 15.3541 11.0565 15.1354C10.161 14.9169 9.25737 14.8076 8.3457 14.8076C7.43387 14.8076 6.53029 14.9169 5.63495 15.1354C4.73945 15.3541 3.86095 15.6756 2.99945 16.0999C2.79812 16.2089 2.63887 16.3496 2.5217 16.5219C2.40437 16.6944 2.3457 16.8819 2.3457 17.0844V17.8076Z" fill="#7069A9"/>
              </g>
            </svg>

            <div class="price__card-list-text">16-30 анкет – 600 грн/контакт</div>
            <div class="price__card-list-label">-25 %</div>
          </div>
        </div>
        <div class="price__btns">
          <a class="price__btn" href="/talents">Дивитись Базу</a>
        </div>
      </div>
      <div class="price__card">
        <p class="price__card-title">Для Талантів</p>
        <p class="price__card-text text-talent">800 грн / рік</p>
        <div class="price__card-list-talent" style="row-gap: 16px">
          <div class="price__card-list-item">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="price__card-list-icon talent-start-icon">
              <mask id="mask0_99_1580" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
              <rect width="24" height="24" fill="#D9D9D9"/>
              </mask>
              <g mask="url(#mask0_99_1580)">
              <path d="M7.875 6.74625L10.5787 3.198C10.7582 2.95783 10.9714 2.7815 11.2183 2.669C11.4651 2.55633 11.7257 2.5 12 2.5C12.2743 2.5 12.5349 2.55633 12.7817 2.669C13.0286 2.7815 13.2417 2.95783 13.4212 3.198L16.125 6.74625L20.2595 8.14225C20.6545 8.26925 20.9625 8.49317 21.1835 8.814C21.4047 9.135 21.5152 9.4895 21.5152 9.8775C21.5152 10.0567
               21.4891 10.2353 21.4368 10.4133C21.3844 10.5913 21.2984 10.7618 21.1788 10.925L18.4865 14.6615L18.5865 18.627C18.6032 19.1535 18.4296 19.5972 18.0658 19.9583C17.7021 20.3194 17.2783 20.5 16.7943 20.5C16.7801 20.5 16.6128 20.4782 16.2923 20.4345L12 19.2038L7.70775 20.4345C7.62442 20.4678 7.53842 20.4871 7.44975 20.4923C7.36108 20.4974 7.27975 20.5 7.20575 20.5C6.71725 20.5 6.29225 20.3194 5.93075 19.9583C5.56925 19.5972 5.39683 19.1535 5.4135 18.627L5.5135 14.6365L2.8365
                10.925C2.71683 10.7612 2.63083 10.5899 2.5785 10.4113C2.52617 10.2324 2.5 10.0537 2.5 9.875C2.5 9.49783 2.60983 9.14708 2.8295 8.82275C3.04917 8.49842 3.356 8.26842 3.75 8.13275L7.875 6.74625ZM8.802 8.0385L4.23075 9.5615C4.13458 9.5935 4.06892 9.65925 4.03375 9.75875C3.99842 9.85808 4.01283 9.94942 4.077 10.0328L7.023 14.1905L6.9135 18.6578C6.907 18.7668 6.94542 18.8533 7.02875 18.9172C7.11208 18.9814 7.20508 18.9975 7.30775 18.9655L12 17.648L16.6923 18.9905C16.7949 19.0225 16.8879 19.0064 16.9712 18.9423C17.0546 18.8783 17.093 18.7917 17.0865 18.6828L16.977 14.1905L19.923 10.0827C19.9872 9.99942 20.0016 9.90808 19.9663 9.80875C19.9311 9.70925 19.8654 9.6435 19.7693 9.6115L15.198 8.0385L12.2405 4.13475C12.1827 4.05125 12.1025 4.0095 12 4.0095C11.8975 4.0095 11.8173 4.05125 11.7595 4.13475L8.802 8.0385Z"
                fill="#7069A9"/>
              </g>
            </svg>
            <div class="price__card-list-text">Отримання пропозицій на зйомку через Telegram-бот</div>
          </div>
          <div class="price__card-list-item">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <mask id="mask0_99_1585" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
              <rect width="24" height="24" fill="#D9D9D9"/>
              </mask>
              <g mask="url(#mask0_99_1585)">
              <path d="M12 17.1152C13.1475 17.1152 14.1202 16.7163 14.9183 15.9183C15.7163 15.1202 16.1152 14.1475 16.1152 13C16.1152 11.8525 15.7163 10.8798 14.9183 10.0818C14.1202 9.28375 13.1475 8.88475 12 8.88475C10.8525 8.88475 9.87975 9.28375 9.08175 10.0818C8.28375 10.8798 7.88475 11.8525 7.88475 13C7.88475 14.1475 8.28375 15.1202 9.08175 15.9183C9.87975 16.7163 10.8525 17.1152 12 17.1152ZM12 15.6155C11.2615 15.6155 10.641 15.3642 10.1385 14.8615C9.63583 14.359 9.3845 13.7385 9.3845 13C9.3845 12.2615 9.63583 11.641 10.1385 11.1385C10.641 10.6358 11.2615 10.3845 12 10.3845C12.7385 10.3845 13.359 10.6358 13.8615 11.1385C14.3642 11.641 14.6155 12.2615 14.6155 13C14.6155 13.7385 14.3642 14.359 13.8615 14.8615C13.359 15.3642 12.7385 15.6155 12 15.6155ZM4.30775 20.5C3.80258 20.5 3.375 20.325 3.025 19.975C2.675 19.625 2.5 19.1974 2.5
               18.6923V7.30775C2.5 6.80258 2.675 6.375 3.025 6.025C3.375 5.675 3.80258 5.5 4.30775 5.5H7.3615L8.66925 4.08275C8.83342 3.90192 9.03183 3.75958 9.2645 3.65575C9.49717 3.55192 9.74233 3.5 10 3.5H14C14.2577 3.5 14.5028 3.55192 14.7355 3.65575C14.9682 3.75958 15.1666 3.90192 15.3307 4.08275L16.6385 5.5H19.6923C20.1974 5.5 20.625 5.675 20.975 6.025C21.325 6.375 21.5 6.80258 21.5 7.30775V18.6923C21.5 19.1974 21.325 19.625 20.975 19.975C20.625 20.325 20.1974 20.5 19.6923 20.5H4.30775ZM4.30775 19H19.6923C19.7821 19 19.8558 18.9712 19.9135 18.9135C19.9712 18.8558 20 18.7821 20 18.6923V7.30775C20 7.21792 19.9712 7.14417 19.9135 7.0865C19.8558 7.02883 19.7821 7 19.6923 7H15.9693L14.1345 5H9.8655L8.03075 7H4.30775C4.21792 7 4.14417 7.02883 4.0865 7.0865C4.02883 7.14417 4 7.21792 4 7.30775V18.6923C4 18.7821 4.02883 18.8558 4.0865
                18.9135C4.14417 18.9712 4.21792 19 4.30775 19Z" fill="#7069A9"/>
              </g>
            </svg>

            <div class="price__card-list-text">Тільки проєкти з гонорарами</div>
          </div>
          <div class="price__card-list-item">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <mask id="mask0_99_1590" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
              <rect width="24" height="24" fill="#D9D9D9"/>
              </mask>
              <g mask="url(#mask0_99_1590)">
              <path d="M5.30775 20.5C4.80908 20.5 4.38308 20.3234 4.02975 19.9703C3.67658 19.6169 3.5 19.1909 3.5 18.6923V5.30775C3.5 4.80908 3.67658 4.38308 4.02975 4.02975C4.38308 3.67658 4.80908 3.5 5.30775 3.5H18.6923C19.1909 3.5 19.6169 3.67658 19.9703 4.02975C20.3234 4.38308 20.5 4.80908 20.5 5.30775V8.029H19V5.30775C19 5.21792 18.9712 5.14417 18.9135 5.0865C18.8558 5.02883 18.7821 5 18.6923 5H5.30775C5.21792 5 5.14417 5.02883 5.0865 5.0865C5.02883 5.14417 5 5.21792 5 5.30775V18.6923C5 18.7821 5.02883 18.8558 5.0865 18.9135C5.14417 18.9712 5.21792 19 5.30775
               19H18.6923C18.7821 19 18.8558 18.9712 18.9135 18.9135C18.9712 18.8558 19 18.7821 19 18.6923V15.971H20.5V18.6923C20.5 19.1909 20.3234 19.6169 19.9703 19.9703C19.6169 20.3234 19.1909 20.5 18.6923 20.5H5.30775ZM13.3077 16.5C12.8091 16.5 12.3831 16.3234 12.0297 15.9703C11.6766 15.6169 11.5 15.1909 11.5 14.6923V9.30775C11.5 8.80908 11.6766 8.38308 12.0297 8.02975C12.3831 7.67658 12.8091 7.5 13.3077 7.5H19.6923C20.1909 7.5 20.6169 7.67658 20.9703 8.02975C21.3234 8.38308 21.5 8.80908 21.5 9.30775V14.6923C21.5 15.1909 21.3234 15.6169 20.9703 15.9703C20.6169
               16.3234 20.1909 16.5 19.6923 16.5H13.3077ZM19.6923 15C19.7821 15 19.8558 14.9712 19.9135 14.9135C19.9712 14.8558 20 14.7821 20 14.6923V9.30775C20 9.21792 19.9712 9.14417 19.9135 9.0865C19.8558 9.02883 19.7821 9 19.6923 9H13.3077C13.2179 9 13.1442 9.02883 13.0865 9.0865C13.0288 9.14417 13 9.21792 13 9.30775V14.6923C13 14.7821 13.0288 14.8558 13.0865 14.9135C13.1442 14.9712 13.2179 15 13.3077 15H19.6923ZM16 13.5C16.4167 13.5 16.7708 13.3542 17.0625 13.0625C17.3542 12.7708 17.5 12.4167 17.5 12C17.5 11.5833 17.3542 11.2292 17.0625 10.9375C16.7708 10.6458 16.4167 10.5 16 10.5C15.5833 10.5 15.2292 10.6458 14.9375 10.9375C14.6458 11.2292 14.5 11.5833 14.5 12C14.5 12.4167 14.6458 12.7708 14.9375 13.0625C15.2292 13.3542 15.5833 13.5 16 13.5Z" fill="#7069A9"/>
              </g>
            </svg>

            <div class="price__card-list-text">Pозміщення анкети в віртуальній базі</div>
          </div>
        </div>
        <div class="price__btns talent-button">
          <modal-trigger name="SelectAuthModal" url='modals/SelectAuthModal' v-slot="{ open }">
          <div class="price__btn" @click="open">Приєднатися</div>
          </modal-trigger>
        </div>
      </div>
    </div>
    <div class="info__wrapper">
      <div class="info">
        <h3 class="info__title">Підписка Pro для Клієнтів</h3>
        <p class="info__text">Всі контакти Талантів відкриті протягом року. Безлімітна кілкість кастингів протягом року та спеціальний тариф для вашої компанії</p>
        <p class="info__subtitle">
          Напишіть нам:
          <a href="mailto:info@castingonline.com.ua" class="info__link">info@castingonline.com.ua</a>
        </p>
      </div>
    </div>
    <div class="cooperation">
      <div class="cooperation__wrapper">
        <h3 class="cooperation__title">
          Співпраця
          <span class="cooperation__title-italic"> з нами</span>
        </h3>
        <div class="cooperation__content">
          <div class="cooperation__left">
            <p class="cooperation__text">Для некомерційних проєктів ми пропонуємо можливість розмістити об’яву в нашому Telegram-каналі.</p>
            <p class="cooperation__text cooperation__text-pt">В цьому випадку ви не будете користуватись фунціоналом платформи, але зможете вказати свою електронну пошту або телефон для контакту.</p>
            <div class="cooperation__list">
              <div class="cooperation__list-item">
                <img src="/static/img/about/circle-tick.png" all="Cooperation" class="cooperation__list-image"/>
                <p class="cooperation__list-text">Надішліть нам текст об'яви та фото на пошту</p>
              </div>
              <div class="cooperation__list-item">
                <img src="/static/img/about/circle-tick.png" all="Cooperation" class="cooperation__list-image"/>
                <p class="cooperation__list-text">Після модерації, отримайте посилання на оплату</p>
              </div>
              <div class="cooperation__list-item">
                <img src="/static/img/about/circle-tick.png" all="Cooperation" class="cooperation__list-image"/>
                <p class="cooperation__list-text">Ваше оголошення доступне в нашому Telegram-каналі</p>
              </div>
            </div>
            <p class="cooperation__price">Вартість оголошення: 300 грн/місяць</p>
            <p class="info__subtitle cooperation__link">
              Напишіть нам
              <a href="mailto:info@castingonline.com.ua" class="info__link">info@castingonline.com.ua</a>
            </p>
          </div>
          <img src="/static/img/cooperation.png" all="Cooperation" class="cooperation__image"/>
        </div>
      </div>
    </div>
    <div class="about-join">
      <div class="about-join__content">
        <h5 class="about-join__title">
          <strong>{{ _('Приєднуйся до') }} </strong>
          <i>{{ _('Casting Online') }}</i>
        </h5>
        <div class="about-join__btn-wrap">
          <modal-trigger name="SelectAuthModal" url='modals/SelectAuthModal' v-slot="{ open }">
          <div class="about-join__chain" @click="open">{{ _('Приєднатись') }}</div></modal-trigger>
          <a
            class="about-join__base"
            href="/talents"
          >
            {{ _('Дивитись Базу') }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'price-page',
}

</script>

<style scoped lang="scss">
.text-talent {
  padding-bottom: 40px !important;
}
.talent-button {
  margin-top: 32px !important;
}

.talent-start-icon {
  margin-top: -12px;
}

.container {
  background-color: #F9F8FC;
  padding-top: 105px;
}

.price {

  &__title {
    text-align: center;
    font-size: 55px;
    line-height: 61px;
    color: #000000;
    letter-spacing: -3px;
    padding-bottom: 40px;
    font-weight: 700;
    font-family: Merriweather;
    @media (max-width: 840px) {
      font-size: 48px;

    }
  }

  &__cards {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    column-gap: 24px;

    @media (max-width: 840px) {
      flex-direction: column;
      row-gap: 24px;
      align-items: center;
      padding: 0 24px;
    }
  }

  &__card {
    width: 380px;
    background-color: #FFFFFF;
    border-radius: 6px;
    padding: 24px 16px;
    @media (max-width: 500px) {
      width: 100%;
    }

    &-title {
      font-weight: 700;
      font-size: 24px;
      line-height: 34px;
      color:#0A0A0A;
      padding-bottom: 8px;
      text-align: center;
      font-family: Merriweather;
    }

    &-text {
      font-size: 14px;
      line-height: 21px;
      color:#0A0A0A;
      padding-bottom: 24px;
      text-align: center;
    }

    &-list {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
    }

    &-list-talent {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
    }

    &-list-item {
      display: flex;
      align-items: center;
      column-gap: 8px;
    }

    &-list-text {
      font-size: 16px;
      line-height: 22px;
      @media (max-width: 500px) {
        font-size: 14px;
        line-height: 19px;
      }
    }

    &-list-label {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 20px;
      background-color: #EBEBFE;
      padding: 0 6px;
      border-radius: 4px;
      color: #212121;
      font-size: 12px;
      line-height: 12px;
    }

    &-list-icon {
      min-width: 24px;
      height: 24px;
    }
  }

  &__btns {
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }

  &__btn {
    display: flex;
    padding: 10px 16px;
    border: 2px solid #7069A9;
    color: #7069A9;
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
    border-radius: 50px;
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      color: #fff;
      background-color: #7069A9;
      box-shadow: 0 4px 15px rgba(112, 105, 169, 0.3);
      transform: translateY(-2px);
    }
  }
}

.info {
  margin: 0 auto;
  max-width: 1160px;
  width: 100%;
  background-color: #E6E6FB;
  border-radius: 8px;
  padding: 40px 48px;
  @media (max-width: 840px) {
      padding: 40px 24px;
  }
  &__wrapper {
    padding: 80px 24px;
    @media (max-width: 840px) {
      padding: 40px 24px;
    }
  }

  &__title {
    font-family: Merriweather;
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;
    padding-bottom: 8px;
    @media (max-width: 500px) {
      text-align: center;
      padding-bottom: 24px;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: -0.3400000035762787px;
    }
  }
  &__text {
    font-size: 18px;
    line-height: 27px;
    padding-bottom: 16px;
    @media (max-width: 500px) {
      text-align: center;
      padding-bottom: 24px;
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__subtitle {
    font-weight: 700;
    font-size: 18px;
    @media (max-width: 500px) {
      text-align: center;
    }
  }

  &__link {
    color: #7069a9;
    text-decoration: underline;
  }
}

.cooperation {
  background-color: #FFFFFF;
  padding: 80px 24px;

  @media (max-width: 860px) {
    padding: 40px 24px;
  }

  &__wrapper {
    margin: 0 auto;
    max-width: 962px;
    width: 100%;
  }

  &__title {
    font-family: Merriweather;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: -2px;
    text-align: center;
    @media (max-width: 600px) {
      font-size: 24px;
    }

    &-italic {
      font-weight: 300;
      font-style: italic;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    margin-top: 34px;
    @media (max-width: 860px) {
      flex-direction: column;
      align-items: center;
      row-gap: 48px;
      margin-top: 40px;
    }
  }

  &__left {
    max-width: 460px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__image {
    width: 423px;
    height: 520px;
    object-fit: contain;
    @media (max-width: 500px) {
      width: 327px;
      height: 402px;
    }
  }

  &__text {
    font-size: 16px;
    line-height: 23px;
    color: #141414;
    font-family: 'Open Sans';

    &-pt {
      padding-top: 28px;
    }
  }

  &__list {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    row-gap: 12px;

    &-item {
      display: flex;
      align-items: center;
      column-gap: 8px;
    }

    &-image {
      width: 24px;
      height: 24px;
    }

    &-text {
      font-size: 16px;
      line-height: 23px;
      color: #141414;
    }
  }

  &__price {
    font-size: 16px;
    line-height: 23px;
    color: #141414;
    font-weight: 700;
    padding-top: 24px;
  }

  &__link {
    padding-top: 24px;
    @media (max-width: 860px) {
      text-align: start;
    }
  }
}
.about-join {
  background: linear-gradient(89deg, #DDDDFE 11.68%, #D3E0F6 81.75%);
  position: relative;
  overflow: hidden;

  display: flex;
  justify-content: center;

  &__base {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 153px;
    height: 49px;
    border: 2px solid #7069A9;
    color: #7069A9;
    border-radius: 50px;
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
    cursor: pointer;
  }

  &__chain {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 142px;
    height: 49px;
    background-color: #63B000;
    color: #FFFFFF;
    border-radius: 50px;
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
    cursor: pointer;

    &:hover {
      background-color: #5CA300;
    }
  }

  &__content {
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    position: static;
    max-width: 367px;
    margin: 0;

    @media (min-width: 1100px) {
      gap: 40px;
      padding-top: var(--layout-10);
      padding-bottom: var(--layout-10);
      max-width: 100%;

      margin: auto;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
    }

    &::before {
      @media (min-width: 1100px) {
        top: 0;
        right: 0;
        width: 215px;
        height: 165px;
        background: url('/static/img/about/join_top.svg') no-repeat top right;
        background-size: contain;
      }
    }

    &::after {
      @media (min-width: 1100px) {
        bottom: -30px;
        left: -60px;
        width: 215px;
        height: 165px;
        background: url('/static/img/about/join_bottom.svg') no-repeat bottom left;
        background-size: contain;
      }
    }
  }

  &__title {
    text-align: center;
    font-family: Merriweather, serif;
    font-weight: 300;
    color: #000;
    font-size: 24px;
    line-height: 34px;
    letter-spacing: -0.0996rem;
    max-width: 248px;

    @media (min-width: 800px) {
      max-width: 430px;
      font-size: 55px;
      line-height: 1.1;
    }

    &--accent {
      font-weight: 700;
    }
  }

  &__btn-wrap {
    display: flex;
    justify-content: center;
    width: 100%;
    column-gap: 16px;
  }

  &__link {
    --bg-color: #000;
    --br-color: #303030;
    border-radius: 100px;
    background-color: var(--bg-color);
    padding: 11px 27px;
    will-change: background-color, border-color;
    transition: background-color 0.3s ease, border-color 0.3s ease;
    max-width: 340px;

    &:hover {
      --br-color: #000;
    }
  }

  &__link-text {
    color: #FFF;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 0.0238rem;
  }

  &__tg-link {
    display: flex;
    align-items: center;
    border-radius: 40px;
    height: 56px;
    background: #4FA0D5;
    will-change: opacity;
    transition: opacity 0.3s ease;
    width: 327px !important;
    justify-content: center;
    flex-direction: row-reverse;
    column-gap: 16px;

    &:hover {
      opacity: 0.8;
    }

    @media (min-width: 800px) {
      width: 340px !important;
    }
  }

  &__tg-link-text {
    color: #FFF;
    text-align: center;
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
  }

  &__tg-link-icon {
    @media (min-width: 1100px) {
      margin-right: var(--space-5);
      margin-left: 0;
    }

    svg {
      display: block;
    }
  }

  &__description {
    color: #100024;
    text-align: center;
    font-family: Open Sans, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;

    @media (min-width: 110px) {
      font-size: 18px;
    }
  }
}

</style>
