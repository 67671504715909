<template>
  <div class="registration-finish">
    <div class="registration-finish_left">
      <div class="registration-finish_left_image">
        <img src="/static/img/registration-women.png" alt="registration-women" width="477" height="555"/>
      </div>
      <div class="registration-finish_left_text">
        <div class="registration-finish_left_text-title">Розпочни свій проект сьогодні</div>
        <div class="registration-finish_left_text-subtitle">Забудь як ти шукав цікавих людей для свого проекту раніше, тепер їх легко можна знайти прямо тут.</div>
      </div>
    </div>
    <div class="registration-finish_right">
      <div class="registration-finish_right_text">
        <div class="registration-finish_right_text-title">Розкажіть нам про себе</div>
        <div class="registration-finish_right_text-subtitle" style="opacity: 80%">
          Ця інформація буде доступна тільки нашій команді і не буде публікуватись публічно.
        </div>
      </div>
      <validation-observer v-slot="{ handleSubmit }" slim ref='validator'>
        <form class="registration-finish_right_form" @submit.prevent='handleSubmit'>
          <div class="registration-finish_right_form_row">
            <div class="registration-finish_right_form_row_item">
              <div class="registration-finish_right_form_row_item-label">Телефон*</div>
              <d-control-input :rules="'required'" name="phoneNumber" v-model='form.phoneNumber' :placeholder="'+380'" />
            </div>
            <div class="registration-finish_right_form_row_item">
              <div class="registration-finish_right_form_row_item-label">Місто*</div>
              <d-control-input :rules="'required'" name="country" v-model='form.country' :placeholder="'Місто знаходження'" />
            </div>
          </div>
          <div class="registration-finish_right_form_row">
            <div class="registration-finish_right_form_row_item">
              <div class="registration-finish_right_form_row_item-label">Назва компаніі*</div>
              <d-control-input :rules="'required'" name="companyName" v-model='form.companyName' :placeholder="'Назва компанії'" />
            </div>
            <div class="registration-finish_right_form_row_item">
              <div class="registration-finish_right_form_row_item-label">Твоя посада</div>
              <d-control-input v-model='form.personCompanyPosition' name="personCompanyPosition" :placeholder="'Назва посади'" />
            </div>
          </div>
          <div class="registration-finish_right_form_row">
            <div class="registration-finish_right_form_row_item registration-finish_right_form_row_item_full-width">
              <div class="registration-finish_right_form_row_item-label">Вебсайт або сторінка в соціальній мережі компанії</div>
              <d-control-input v-model='form.companyRef' name="companyRef" :placeholder="'Посилання'"/>
            </div>
          </div>
          <button @click.prevent="() => {handleSubmit(); send()}" type="submit" class="el-btn el-btn--default text text--space-lg text--color-white text--weight-bold registration-finish_right_form_button">
            Продовжити
          </button>
        </form>
      </validation-observer>
    </div>
  </div>
</template>
<script>
import { signUpClientFinishAPI } from '@requests/services/services';

export default {
  name: 'client-registration-finish',
  data() {
    return {
      nonFieldErrors: null,
      form: {
        phoneNumber: null,
        country: null,
        companyName: null,
        personCompanyPosition: null,
        companyRef: null,
      },
    }
  },
  methods: {
    send() {
      const data = JSON.parse(JSON.stringify({
        phone: this.form.phoneNumber,
        place: this.form.country,
        companyName: this.form.companyName,
        companyLocation: this.form.personCompanyPosition,
        socialLink: this.form.companyRef,
      }))

      if (this.form.phoneNumber && this.form.country && this.form.companyName) {
        signUpClientFinishAPI.execute({}, data).then(() => {
          window.location.href = '/cabinet/?finishedRegistration=true'

          window.IS_ACTIVE = 'true'
        })
      }
    },
  },
}
</script>
<style lang="scss">
.control-descriptor__errors {
  background-color: white !important;
  color: black !important;

  padding: 8px 12px !important;
  max-width: none !important;
  width: fit-content !important;

  box-shadow: 0px 1px 4px rgba(12, 12, 13, 0.1) !important;
  border: 1px solid #F2F2F2 !important;
  top: calc(100% - 9px) !important;
  right: 8px !important;

  &::after {
    display: none;
  }
}

.d-control-input {
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 16.8px !important;
  text-align: left;
}
</style>
<style lang="scss" scoped>
.control-descriptor {
  margin-bottom: 0;
  margin-top: -9px;
}

.registration-finish {
  width: 100%;
  min-height: 100vh;

  display: flex;
}

.registration-finish_left {
  display: flex;
  flex-direction: column;
  min-width: 445px;
  min-height: 100vh;

  @media (max-width: 768px) {
    display: none;
  }
}

.registration-finish_left_image {
  position: relative;

  height: 63%;
  min-height: 63vh;
  width: 100%;

  background: #E6E6FB;

  img {
    position: absolute;
    bottom: -1px;

    @media (max-height: 840px) {
      top: 5px;
    }
  }
}

.registration-finish_left_text {
  display: flex;
  flex-direction: column;
  gap: 16px;

  height: 37%;

  background: #1E1E1E;
  padding: 32px 67px;
}

.registration-finish_left_text-title {
  font-family: Merriweather, sans-serif;
  font-size: 24px;
  font-weight: 300;
  line-height: 34.6px;
  letter-spacing: -0.7200000286102295px;
  text-align: center;

  color: white;
}

.registration-finish_left_text-subtitle {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;

  color: white;
  opacity: 0.8;
}

.registration-finish_right {
  max-width: 623px;
  width: 100%;

  margin-left: 13.5%;
  margin-right: 12.4%;
  margin-top: 80px;

  display: flex;
  flex-direction: column;
  gap: 48px;

  @media (max-width: 1080px) {
    margin-left: 5%;
    margin-right: 4%;
  }

  @media (max-width: 768px) {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }

  &_text {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &-title {
      font-family: Open Sans;
      font-size: 32px;
      font-weight: 700;
      line-height: 48px;
    }

    &-subtitle {
      font-family: Open Sans;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  &_form {
    display: flex;
    flex-direction: column;
    gap: 40px;

    @media (max-width: 768px) {
      gap: 24px;
    }

    &_row {
      display: flex;
      gap: 16px;

      @media (max-width: 768px) {
        flex-direction: column;
        gap: 24px;
      }

      width: 100%;

      &_item {
        width: 49%;

        @media (max-width: 768px) {
          width: 100%;
        }

        &_full-width {
          width: 100%;
        }

        &-label {
          font-family: Open Sans;
          font-size: 14px;
          font-weight: 600;
          line-height: 16.8px;
        }
      }
    }

    &_button {
      font-family: Open Sans;
      font-size: 14px;
      font-weight: 600;
      line-height: 16.8px;
      letter-spacing: 0;

      width: fit-content;

      align-self: end;

      padding: 15.5px 16px;

      @media (max-width: 768px) {
        align-self: center;
        width: 100%;
        margin-top: 16px;
        margin-bottom: 40px;
      }
    }
  }
}

.el-btn {
  &:hover {
    background: #5CA300;
    color: white;
  }
}
</style>
