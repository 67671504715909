<template>
  <div class="cabinet-project-bar">
    <div class="cabinet-project-container" :class="{'cabinet-project-container-table': isTable, 'cabinet-project-container-disable': project.status !== 0}">
      <div :class="['cabinet-project-wrapper', {
        'cabinet-project-wrapper-disable': project.status !== 0
      }]" @click="goToEdit(id)">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <mask id="mask0_2598_20123" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
          <rect width="24" height="24" fill="#D9D9D9"/>
          </mask>
          <g mask="url(#mask0_2598_20123)">
            <path d="M5 19H6.2615L16.498 8.7635L15.2365 7.502L5 17.7385V19ZM4.404 20.5C4.14783 20.5 3.93317 20.4133 3.76 20.24C3.58667 20.0668 3.5 19.8522 3.5 19.596V17.8635C3.5 17.6197 3.54683 17.3873 3.6405 17.1663C3.734 16.9453 3.86283 16.7527 4.027 16.5885L16.6905
            3.93075C16.8417 3.79342 17.0086 3.68733 17.1913 3.6125C17.3741 3.5375 17.5658 3.5 17.7663 3.5C17.9668 3.5 18.1609 3.53558 18.3488 3.60675C18.5367 3.67792 18.7032 3.79108 18.848 3.94625L20.0693 5.18275C20.2244 5.32758 20.335 5.49425 20.401 5.68275C20.467 5.87125
             20.5 6.05975 20.5 6.24825C20.5 6.44942 20.4657 6.64133 20.397 6.824C20.3283 7.00683 20.2191 7.17383 20.0693 7.325L7.4115 19.973C7.24733 20.1372 7.05475 20.266 6.83375 20.3595C6.61275 20.4532 6.38033 20.5 6.1365 20.5H4.404ZM15.8562 8.14375L15.2365 7.502L16.498
             8.7635L15.8562 8.14375Z" fill="#212121"/>
          </g>
        </svg>
        <p>{{ _("Редагувати") }}</p>
      </div>
      <div :class="['cabinet-project-wrapper', {
        'cabinet-project-wrapper-disable': project.status !== 0
      }]" @click="deleteHandler(project)">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <mask id="mask0_2905_13814" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
          <rect width="24" height="24" fill="#D9D9D9"/>
          </mask>
          <g mask="url(#mask0_2905_13814)">
            <path d="M7.30775 20.4997C6.81058 20.4997 6.385 20.3227 6.031 19.9687C5.677 19.6147 5.5 19.1892 5.5 18.692V5.99973H5.25C5.0375 5.99973 4.85942 5.92782 4.71575 5.78398C4.57192 5.64015 4.5 5.46198 4.5 5.24948C4.5 5.03682 4.57192 4.85873 4.71575 4.71523C4.85942 4.57157 5.0375 4.49973 5.25 4.49973H9C9 4.2549 9.08625 4.04624 9.25875 3.87374C9.43108 3.7014 9.63967 3.61523 9.8845 3.61523H14.1155C14.3603 3.61523 14.5689 3.7014 14.7413 3.87374C14.9138 4.04624 15 4.2549 15 4.49973H18.75C18.9625 4.49973 19.1406 4.57165 19.2843
             4.71548C19.4281 4.85932 19.5 5.03748 19.5 5.24998C19.5 5.46265 19.4281 5.64073 19.2843 5.78423C19.1406 5.9279 18.9625 5.99973 18.75 5.99973H18.5V18.692C18.5 19.1892 18.323 19.6147 17.969 19.9687C17.615 20.3227 17.1894 20.4997 16.6923 20.4997H7.30775ZM17 5.99973H7V18.692C7 18.7818 7.02883 18.8556 7.0865 18.9132C7.14417 18.9709 7.21792 18.9997 7.30775 18.9997H16.6923C16.7821 18.9997 16.8558 18.9709 16.9135 18.9132C16.9712 18.8556 17 18.7818 17 18.692V5.99973ZM10.1543 16.9997C10.3668 16.9997 10.5448 16.9279 10.6885 16.7842C10.832 16.6404 10.9037 16.4622 10.9037 16.2497V8.74973C10.9037 8.53723 10.8318 8.35907 10.688 8.21523C10.5443 8.07157
              10.3662 7.99973 10.1535 7.99973C9.941 7.99973 9.76292 8.07157 9.61925 8.21523C9.47575 8.35907 9.404 8.53723 9.404 8.74973V16.2497C9.404 16.4622 9.47583 16.6404 9.6195 16.7842C9.76333 16.9279 9.94158 16.9997 10.1543 16.9997ZM13.8465 16.9997C14.059 16.9997 14.2371 16.9279 14.3807 16.7842C14.5243 16.6404 14.596 16.4622 14.596 16.2497V8.74973C14.596 8.53723 14.5242 8.35907 14.3805 8.21523C14.2367 8.07157 14.0584 7.99973 13.8458 7.99973C13.6333 7.99973 13.4552 8.07157 13.3115 8.21523C13.168 8.35907 13.0962 8.53723 13.0962 8.74973V16.2497C13.0962 16.4622 13.1682 16.6404 13.312 16.7842C13.4557 16.9279 13.6338 16.9997 13.8465 16.9997Z"
              fill="#EF2A2A"/>
          </g>
        </svg>
        <p class="delete">{{ _("Видалити") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import DeleteProjectModal from '@app/modals/DeleteProjectModal.vue';

export default {
  name: 'cabinet-project-bar',
  props: ['id', 'project', 'updatesPreviewProject', 'updateProject', 'isRedirect', 'isTable'],
  computed: {
  },
  data() {
    return {
    }
  },
  methods: {
    hideBar() {
      this.$emit('close-bar')
    },
    goToEdit(id) {
      window.location = `/client/edit-project/${id}`;
    },
    deleteHandler(project) {
      this.$modal.show(DeleteProjectModal,
        {
          project,
          updatesPreviewProject: this.updatesPreviewProject,
          updateProject: this.updateProject,
          isRedirect: this.isRedirect,
        },
        {
          adaptive: true,
        })
      this.hideBar()
    },
  },
}
</script>

<style lang="scss">
.cabinet-project-bar {
  position: relative;
}

.cabinet-project-container {
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
  z-index: 10;
  right: 35px;
  top: -3px;
  min-width: 217px;
  border: 1px solid #CCCCCC;
  padding: 8px 16px;

  @media (max-width: 780px) {
    right: 8px;
    top: 0;
  }

  &-disable {
    cursor: not-allowed;
  }
}

.cabinet-project-container-table {
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
  z-index: 10;
  right: 30px;
  top: -10px;
  min-width: 217px;
  border: 1px solid #CCCCCC;
  padding: 8px 16px;

  @media (max-width: 1150px) {
    right: -5px;
    top: 0;
  }
}
.cabinet-project-wrapper {
  display: flex;
  align-items: center;
  column-gap: 8px;
  padding: 10px 0;
  cursor: pointer;
  font-size: 14px;

  &-disable {
    pointer-events: none;
    cursor: not-allowed;
    color: #333333 !important;
  }
}

.delete {
  font-size: 14px;
  color: #F14747;
}

</style>
<style lang="css">
::v-deep(.tooltip .tooltip-inner) {
  background: transparent !important;
  border: none !important;
  color: #333; /* Example: Change text color */
  box-shadow: none; /* Example: Remove shadow */
  padding: 8px 12px; /* Adjust padding */
  font-size: 14px; /* Adjust font size */
}

.tooltip .tooltip-inner {
  background: transparent !important;
  border: none !important;
  color: #333; /* Example: Change text color */
  box-shadow: none; /* Example: Remove shadow */
  padding: 8px 12px; /* Adjust padding */
  font-size: 14px; /* Adjust font size */
}
</style>
