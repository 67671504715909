<template lang='pug'>

  resource-loader-controller(
    :resource="attributesResource"
    v-slot="allProps"
  )
    resource-loader-controller(
      :resource="additionalResource"
      v-slot="additionalProps"
    )
      component(
        :is="filterName"
        :value="filters"
        @input="changeFilters"
        :allProps="allProps",
        :pagination='pagination'
        :label='label',
        :needSearch="needSearch"
        :additionalProps="additionalProps"
        :additionalResource="additionalResource"
        :totalItems="totalItems"
        @get:filters:list="$emit('get:filters:list', $event)"
        :hasOtherFilter="hasOtherFilter"
        :changeFilters="changeFilters"
      )
</template>

<script>
import DefaultFilter from '@app/Filters'
import CabinetFilter from '@app/Filters/Cabinet'

export default {
  components: {
    CabinetFilter,
    DefaultFilter,
  },

  props: [
    'filters',
    'pagination',
    'attributesResource',
    'additionalResource',
    'label',
    'filterName',
    'needSearch',
    'totalItems',
    'hasOtherFilter',
  ],
  methods: {
    changeFilters(value) {
      const params = Object.assign(value, { label: this.label })

      // TODO: Remove this hardcode
      if ('undefined' === typeof params.filters?.gender) {
        delete params.filters?.gender
      }

      this.$emit('update:filters', params)
    },
  },
}

</script>
