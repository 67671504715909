<template>
  <div>
    <video ref="video" :src="videoSrc" @loadeddata="captureFrame" style="display: none"></video>
    <canvas ref="canvas" class="canvas"></canvas>
    <img :src="previewDataUrl" alt="Video Preview" v-if="previewDataUrl"/>
  </div>
</template>

<script>
export default {
  name: 'video-preview',
  data() {
    return {
      previewDataUrl: null,
    };
  },
  props: {
    videoSrc: String,
  },
  mounted() {
    this.$refs.video.load();
  },
  methods: {
    captureFrame() {
      const { video } = this.$refs;
      const { canvas } = this.$refs;
      const ctx = canvas.getContext('2d');

      const containerWidth = 93;
      const containerHeight = 93;

      canvas.width = containerWidth;
      canvas.height = containerHeight;

      video.currentTime = 1;

      video.addEventListener('seeked', () => {
        const { videoWidth } = video;
        const { videoHeight } = video;

        const scale = Math.max(containerWidth / videoWidth, containerHeight / videoHeight);
        const scaledWidth = videoWidth * scale;
        const scaledHeight = videoHeight * scale;

        const dx = (containerWidth - scaledWidth) / 2;
        const dy = (containerHeight - scaledHeight) / 2;

        ctx.drawImage(video, dx, dy, scaledWidth, scaledHeight);

        this.previewDataUrl = canvas.toDataURL('image/png');
      }, { once: true });
    },
  },
}
</script>

<style lang="scss" scoped>
.canvas {
  display: block;
}
</style>
