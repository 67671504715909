<template>
  <div class="header-project-bar">
    <div class="header-project-container" >
      <div class="list">
        <div v-for="project in projects.filter(item => item.status === 0 || item.status === 1)" :key="project.id" >
          <a :href="`/cabinet/client/projects-client/${project.id}`" class="link"> {{ project.name }}</a>
        </div>
      </div>
      <div class="divider"></div>
      <div class="btn-wrapper">
        <button class="add" @click="addCastingClick">
          <img class="add-icon" src="/static/img/add-icon.svg" width="14" height="14" alt="add-icon" />
          Створити кастинг
        </button>
        <button class="add" @click="handleAllCastingsClick">
          Всі кастинги
        </button>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'header-project-bar',
  props: ['projects'],
  computed: {
  },
  data() {
    return {
    }
  },
  methods: {
    hideBar() {
      this.$emit('close-bar')
    },
    goToEdit(id) {
      window.location = `/client/edit-project/${id}`;
    },
    addCastingClick() {
      window.location.href = '/create-project'
    },
    handleAllCastingsClick() {
      window.location.href = '/cabinet'
    },
  },
}
</script>

<style lang="scss">
.header-project-bar {
  position: relative;
}

.header-project-container {
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
  z-index: 9999 !important;
  right: 45px;
  top: -3px;
  min-width: 252px;
  max-width: 252px;
  border: 1px solid #C8C8D0;
  padding: 8px 16px;

  position: relative;
  @media (max-width: 780px) {
    right: 8px;
    top: 0;
  }
}

.divider {
  border-bottom: 1px solid #CCCCCC;
  margin: 0 -16px
}

.link {
  color: #0C0C0E;
  font-size: 14px;

  &:hover {
    color: #5CA300
  }
}

.list {
  display: flex;
  flex-direction: column;
  row-gap: 22px;
  margin: 12px 0;
  max-height: 250px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }
}

.add {

  display: flex;
  align-items: center;
  gap: 8px;

  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.8px;

  color: #63B000;

  padding: 6px 14px;

  border-radius: 40px;
  border: 2px solid #63B000;

  background: transparent;
}

.btn-wrapper {
  padding: 16px 0 8px;

  display: flex;
  flex-direction: column;
  gap: 8px;
}
</style>
